@import url('https://fonts.googleapis.com/css?family=Architects+Daughter');

/** Common classes for children components **/
.handwritingFont {
  font-family: 'Architects Daughter', cursive;
}

.staleColor {
  composes: has-text-grey from global;
  border-color: hsl(0, 0%, 48%) !important; /* bulma grey */
}

.freshColor {
  composes: has-text-grey-darker from global;
  border-color: hsl(0, 0%, 21%) !important; /* bulma grey-darker */
}

.fillTopPart {
  flex: 1;
  align-self: stretch;
}

/** Private Stuff **/
.topPart {
  flex: 5 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.bottomPart {
  flex: 3 0;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
}
