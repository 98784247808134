.title {
  composes: modalTitle from '../../styles.module.css';
}

.buttons {
  width: 100%;
}

.buttonTitle {
  composes: title from global;
  composes: is-4 from global;
  composes: is-marginless from global;
  composes: has-text-left from global;
}

.buttonBody {
  composes: has-text-left from global;
  white-space: normal;
  text-align: start;
}

.button {
  composes: button from global;
  composes: is-block from global;
  height: unset !important;
  width: 100%;
}

.button:not(:last-child) {
  margin-bottom: 1rem;
}

.link {
  composes: link from global;
}
