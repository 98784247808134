.selectWrapper {
  composes: select from global;
  composes: is-medium from global;
  margin-bottom: 1rem;
}

.selectElement {
  width: 100%;
}

.helpText {
  composes: has-text-weight-normal from global;
}

.link {
  composes: link from global;
}
