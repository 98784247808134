.buttonRow {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
}

.subHeading {
  margin-bottom: 0.5rem !important;
}

.modalTitle {
  composes: title from global;
  composes: is-2 from global;
}
