.container {
  composes: tile from global;
  composes: is-ancestor from global;
}

.leftSide {
  composes: tile from global;
  composes: is-parent from global;
  composes: is-4 from global;
  composes: is-vertical from global;
}

.rightSide {
  composes: tile from global;
  composes: is-parent from global;
}

.child {
  composes: tile from global;
  composes: box from global;
  composes: is-child from global;
  composes: is-flex from global;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.rolloutResult {
  composes: tile from global;
  composes: box from global;
  composes: is-child from global;
  composes: is-flex from global;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  background-image: url(./linedpaper.png);
  background-repeat: repeat;
}
