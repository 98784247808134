.link {
  composes: link from global;
  /* composes: has-text-grey-dark from global; */
  /* composes: has-text-grey from global; */
  composes: has-text-grey-dark from global;
  /* composes: has-text-weight-semibold from global; */
}

.list {
}

.credit {
  composes: has-text-grey from global;
}

.credit:not(:last-child) {
  margin-bottom: 1rem;
}
