.header {
  composes: hero from global;
}

.headerBody {
  composes: hero-body from global;
}

.title {
  composes: title from global;
  composes: is-1 from global;
}

.subtitle {
  composes: subtitle from global;
  composes: is-5 from global;
}

.pageBody {
  composes: section from global;
}

.container {
  composes: container from global;
}

.footer {
  composes: footer from global;
}

.footerContent {
  composes: container;
  composes: is-flex from global;
  /* flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; */
}

.iconContainer {
  composes: icon from global;
  composes: link from global;
  composes: is-medium from global;
  composes: has-text-grey-light from global;
  position: absolute;
  top: 1rem;
  right: 2rem;
}

/* Bulma Mobile query */
@media screen and (max-width: 768px) {
  .iconContainer {
    top: 1rem;
    right: 1rem;
  }
}
