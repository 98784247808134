.button {
  composes: button from global;
  /* composes: is-medium from global; */
}

.modal {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  /* width: 45rem; */
}
