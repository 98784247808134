.container {
  composes: fillTopPart from '../../styles.module.css';
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.attrCommon {
  composes: handwritingFont from '../../styles.module.css';
}

.attribute {
  composes: attrCommon;
  composes: freshColor from '../../styles.module.css';
}

.staleAttribute {
  composes: attrCommon;
  composes: staleColor from '../../styles.module.css';
}

.attrFontCommon {
  composes: is-size-2-desktop from global;
  composes: is-size-3-tablet from global;
}

.attrFontShortLine {
  composes: attrFontCommon;
  composes: is-size-3-mobile from global;
}

.attrFontMedLine {
  composes: attrFontCommon;
  composes: is-size-4-mobile from global;
}

.attrFontLongLine {
  composes: attrFontCommon;
}

/* Bulma Mobile Query */
@media screen and (max-width: 768px) {
  .attrFontLongLine {
    font-size: 6vw !important;
  }
}
